import React from "react";
import {Link } from "react-router-dom";
import {progressbar} from "./components/api";
import _t, {setLocal} from "./i18n";

export default function Home() {

  progressbar(0);

  return (
    <div className="uk-container uk-container-small uk-light">
      <div className="lang-select uk-text-center uk-margin-large-bottom">
          <><a onClick={e => setLocal("bs")}><img src="https://flagcdn.com/32x24/ba.png" srcSet="https://flagcdn.com/64x48/ba.png 2x,https://flagcdn.com/96x72/ba.png 3x" width="32" height="24" alt="Bosnia and Herzegovina" className="uk-margin-right" /></a>
          <a onClick={e => setLocal("en")}><img src="https://flagcdn.com/32x24/gb.png" srcSet="https://flagcdn.com/64x48/gb.png 2x,https://flagcdn.com/96x72/gb.png 3x" width="32" height="24" alt="English" /></a>          
        </>
      </div>
      <div className="uk-grid-large uk-child1-width-1-1@m uk-grid-divider1" data-uk-grid>
      <div className="uk-width-1-1 uk-width-1-2@m uk-hidden">
              <div className="uk-text-center">
                <img src="tickets.png" width="80px" />
                <h2>{ /* _t("Odaberi tribinu") */} { _t("Čizma Bolero i Proleteri")}</h2>
                <p>
                { /* _t("Odabirom ove opcije kupujete fizičku ulaznicu za utakmicu.") */}
                {_t("Odabirom ove opcije kupujete ulaznicu za koncert.")}
                </p>
                <Link to="/odaberi-tribinu" className="uk-button uk-button-default">
                {_t("Odaberi")}
                </Link>
              </div>
            </div>

            <div className="uk-width-1-1 uk-width-1-2@m uk-hidden">
            <div className="uk-text-center">
            <img src="worldwide.png" width="80px" />
              <h2>{_t("Želim virtuelnu ulaznicu")}</h2>
              <p>
              {_t("Želim pružiti podršku Klubu kupovinom virtuelne ulaznice.")}
              </p>
              <Link to="/virtuelna-ulaznica" className="uk-button uk-button-default">{_t("Odaberi")}</Link>
            </div>
          </div>
      <div className="uk-width-1-1 uk-width-1-1@m">
            <div className="uk-text-center">
            <img src="fans.png" width="90px" />
              <h2>{_t("Sezonska ulaznica")}</h2>
              <p>
              {_t("Odaberi tribinu")}
              </p>
              <Link to="/sezonska-ulaznica" className="uk-button uk-button-default">{_t("Odaberi")}</Link>
            </div>
          </div>

  
          
        </div>
    </div>
  );
}
